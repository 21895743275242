import React, { useEffect, useState } from 'react'
import logo from '../../assets/logo.png'
import icon from '../../assets/icons/navicon.png'
import cross from '../../assets/icons/crossicon.png'
import search from '../../assets/icons/search.svg'
import cart from '../../assets/icons/cart.svg'
import heart from '../../assets/icons/date.png'
import right from '../../assets/icons/pinkright.png'
import down from '../../assets/icons/pinkdown.png'
import ig from '../../assets/icons/igdark.png'
import mail from '../../assets/icons/maildark.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useShoppingCart } from '../../context/ShoppingCartContext'
import './Nav.css'
import Cart from '../Cart/Cart'


const Nav = ({ loveletters_ref }) => {

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [navItemExpand, setNavItemExpand] = useState(false);
  const [navItemExpand2, setNavItemExpand2] = useState(false);
  const [mobilenavItemExpand, setmobileNavItemExpand] = useState(false);
  const [mobilenavItemExpand2, setmobileNavItemExpand2] = useState(false);
  const [mobilenavItemExpand3, setmobileNavItemExpand3] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');


  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsNavOpen(false)
    setCartIsOpen(false)
    setIsSearchOpen(false)
    setSearchInput('')
  }, [location.pathname])

  const closeNav = (e) => {
    if (e.target.classList.contains('NavContainer') || (e.target.classList.contains('NavMobileContainer'))) {
      setIsNavOpen(false)
    }
  }

  const { cartQuantity, cartIsOpen, setCartIsOpen } = useShoppingCart();

  const findSearchedInput = () => {
    if (searchInput) {
      navigate(`/product/${searchInput}`)
    }
    else {
      setIsSearchOpen(!isSearchOpen)
    }
  }

  useEffect(() => {
    if (mobilenavItemExpand) {
      setmobileNavItemExpand2(false);
      setmobileNavItemExpand3(false);
    }
  }, [mobilenavItemExpand]);

  useEffect(() => {
    if (mobilenavItemExpand2) {
      setmobileNavItemExpand(false);
      setmobileNavItemExpand3(false);
    }
  }, [mobilenavItemExpand2]);

  useEffect(() => {
    if (mobilenavItemExpand3) {
      setmobileNavItemExpand(false);
      setmobileNavItemExpand2(false);
    }
  }, [mobilenavItemExpand3]);

  const scrollToLoveletters = async () => {
    if (location.pathname == '/') {
      setIsNavOpen(false);
      loveletters_ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
    else {
      await navigate('/');
      setIsNavOpen(false);
      loveletters_ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    // console.log(navItemExpand2)
   if(navItemExpand2) setNavItemExpand(false);
  }, [navItemExpand2])

  useEffect(() => {

   if(navItemExpand) setNavItemExpand2(false);
  }, [navItemExpand])
  
  const handleCollection =()=>{
    console.log(navItemExpand)
    setNavItemExpand(!navItemExpand);
  }

  return (
    <>

      <div className='sticky top-0 flex grid grid-cols-3 items-center px-5 sm:px-16 h-[80px] items-center justify-center font-body font-light bg-[var(--black)] text-sm z-10 font-body'>

        <div className=''>
          {isNavOpen ?
            <img src={cross} className='w-4 max-w-[100%] cursor-pointer' onClick={() => setIsNavOpen(false)} />
            : <img src={icon} className='h-4 max-h-[100%] cursor-pointer' onClick={() => setIsNavOpen(true)} />
          }
        </div>

        <div className='flex justify-center items-center'>
          <Link to='/'><img src={logo} alt="logo" className='h-[56px] object-contain' /></Link>
        </div>

        <div className='flex justify-end items-center gap-5 sm:gap-12'>
          <div className='flex gap-2 items-center'>
            {isSearchOpen &&
              <input
                className='input font-subheading navSearch' type='text' placeholder='search product code'
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value.toUpperCase())}
              />
            }
            <img src={search} className='w-4 max-w-[100%] cursor-pointer' onClick={findSearchedInput} />
          </div>

          <div className='relative cursor-pointer' onClick={() => setCartIsOpen(true)}>
            <img src={cart} className='w-4 max-w-[100%]' />

            {cartQuantity > 0 ?
              <div className='absolute bottom-[-10px] right-[-10px] rounded-xl bg-white text-[var(--secondary)] text-sm custom-shadow h-4 w-4 flex items-center justify-center'>{cartQuantity}</div>
              : null}
          </div>

          <Link to='/bookanappointment' className='hidden sm:block'><img src={heart} className='w-4 max-w-[100%]' /></Link>
        </div>



        <div className={isNavOpen ? 'NavContainer navAnim1 active' : 'NavContainer navAnim1'}
          onClick={(e) => closeNav(e)}
        >
          <div className={isNavOpen ? 'NavMenu navAnim active' : 'NavMenu navAnim'}>

            <div>
              <div className='navHead'>SHOP</div>

              <div className={navItemExpand2 ? 'navItem selected' : 'navItem'} onClick={() => {
                setNavItemExpand2(!navItemExpand2)

                
              }}>
                SHOP BY CATEGORY
              </div>

              <div className={navItemExpand2 ? 'navAnim active navItemExpand' : 'navAnim navItemExpand'}>
                <div className='navItem'>
                  <Link to='/collection'>all products</Link>
                </div>
                <div className='navItem'>
                  <Link to='/collection/Rings'>rings</Link>
                </div>
                {/* <div className='navItem'>
                  <Link to='/collection'>engagement rings</Link>
                </div> */}
                <div className='navItem'>
                  <Link to='/collection/Neckpieces'>neckpieces</Link>
                </div>
                <div className='navItem'>
                  <Link to='/collection/Earrings'>earrings</Link>
                </div>
                <div className='navItem'>
                  <Link to='/collection/Wristwear'>wristwear</Link>
                </div>
              </div>

              <div className={navItemExpand ? 'navItem selected' : 'navItem'} onClick={() => {
              
                handleCollection();
              }}>
                SHOP BY COLLECTION
              </div>

              <div className={navItemExpand ? 'navAnim active navItemExpand' : 'navAnim navItemExpand'}>
                <div className='navItem'>
                  <Link to='/onthemove'>on the move</Link>
                </div>
                {/* <div className='navItem'>
                  <Link to='/shopcollection'>essentials</Link>
                </div> */}
              </div>

              <div className='navItem'>
                <Link to='/collection'>NEW ARRIVALS</Link>
              </div>
            </div>

            <div>
              <div className='navHead'>DISCOVER</div>

              <div className='navItem'>
                <Link to='/ourstory'>about us</Link>
              </div>
              <div className='navItem'>
                <Link to='/bespokeprocess'>Bespoke Process</Link>
              </div>
              <div className='navItem'>
                  <Link to='/customengagementrings'>custom engagement rings</Link>
                </div>
              <div className='navItem cursor-pointer'
                onClick={() => scrollToLoveletters()}
              >
                Love letters
              </div>
              <div className='navItem'>
                <Link to='/bookanappointment'>Book an Appointment</Link>
              </div>
              <div className='navItem'>
                <Link to='/contactus'>Contact</Link>
              </div>
              <div className='navItem'>
                <Link to=''>Connect on Instagram</Link>
              </div>

            </div>


            <div>
              <div className='navHead'>Care</div>

              <div className='navItem'>
                <Link to='/faqs'>FAQs</Link>
              </div>
              <div className='navItem'>
                <Link to='/sizeguide'>Size Guide</Link>
              </div>
              <div className='navItem'>
                <Link to='/labdiamondsguidance'>Lab Diamond Guidance</Link>
              </div>
              <div className='navItem'>
                <Link to=''>Privacy Policy</Link>
              </div>
              <div className='navItem'>
                <Link to=''>Terms and Conditions</Link>
              </div>

            </div>

          </div>
        </div>

        <div className={isNavOpen ? 'NavMobileContainer navAnim1 active' : 'NavMobileContainer navAnim1'}
          onClick={(e) => closeNav(e)}
        >
          <div className={isNavOpen ? 'NavMenu navAnim active' : 'NavMenu navAnim'}>

            <div className='w-full'>

              <div className='flex justify-between'
                onClick={() => setmobileNavItemExpand(!mobilenavItemExpand)}>
                <div className='navHead'>SHOP</div>
                <div>
                  <img src={mobilenavItemExpand ? down : right} className='w-6 h-6 object-contain max-w-[100%] max-h-[100%]' />
                </div>
              </div>

              <div className={mobilenavItemExpand ? 'navAnim active navItemExpandMobile' : 'navAnim navItemExpandMobile'}>

                <div className={navItemExpand2 ? 'navItem selected' : 'navItem'} onClick={() => setNavItemExpand2(!navItemExpand2)}>
                  SHOP BY CATEGORY
                </div>

                <div className={navItemExpand2 ? 'navAnim active navItemExpand2' : 'navAnim navItemExpand2'}>
                  <div className='navItem'>
                    <Link to='/collection'>all products</Link>
                  </div>
                  <div className='navItem'>
                    <Link to='/collection'>rings</Link>
                  </div>
                  {/* <div className='navItem'>
                    <Link to='/collection'>engagement rings</Link>
                  </div> */}
                  <div className='navItem'>
                    <Link to='/collection'>neckpieces</Link>
                  </div>
                  <div className='navItem'>
                    <Link to='/collection'>earrings</Link>
                  </div>
                  <div className='navItem'>
                    <Link to='/collection'>wristwear</Link>
                  </div>
                </div>

                <div className={navItemExpand ? 'navItem selected' : 'navItem'} onClick={() => setNavItemExpand(!navItemExpand)}>
                  SHOP BY COLLECTION
                </div>

                <div className={navItemExpand ? 'navAnim active navItemExpand' : 'navAnim navItemExpand'}>
                  <div className='navItem'>
                    <Link to='/onthemove'>on the move</Link>
                  </div>
                  {/* <div className='navItem'>
                    <Link to='/shopcollection'>essentials</Link>
                  </div> */}
                </div>

                <div className='navItem'>
                  <Link to='/collection'>NEW ARRIVALS</Link>
                </div>
              </div>

            </div>

            <div className='w-full'>
              <div className='flex justify-between'
                onClick={() => setmobileNavItemExpand2(!mobilenavItemExpand2)}>
                <div className='navHead'>DISCOVER</div>
                <div>
                  <img src={mobilenavItemExpand2 ? down : right} className='w-6 h-6 object-contain max-w-[100%] max-h-[100%]' />
                </div>
              </div>

              <div className={mobilenavItemExpand2 ? 'navAnim active navItemExpandMobile' : 'navAnim navItemExpandMobile'}>
                <div className='navItem'>
                  <Link to='/bookanappointment'>Book an Appointment</Link>
                </div>
                <div className='navItem'>
                  <Link to='/ourstory'>about us</Link>
                </div>
                <div className='navItem'>
                  <Link to='/bespokeprocess'>Bespoke Process</Link>
                </div>
                <div className='navItem'>
                  <Link to='/customengagementrings'>custom engagement rings</Link>
                </div>
                <div className='navItem'
                  onClick={() => scrollToLoveletters()}
                >
                  Love letters
                </div>
                <div className='navItem'>
                  <Link to='/contactus'>Contact</Link>
                </div>
                <div className='navItem'>
                  <Link to='https://www.instagram.com/moheerajewels?igsh=MTNxMGF4NnQ4cnpvNg==' target='_blank'>Connect on Instagram</Link>
                </div>

              </div>

            </div>


            <div className='w-full'>
              <div className='flex justify-between'
                onClick={() => setmobileNavItemExpand3(!mobilenavItemExpand3)}>
                <div className='navHead'>CARE</div>
                <div>
                  <img src={mobilenavItemExpand3 ? down : right} className='w-6 h-6 object-contain max-w-[100%] max-h-[100%]' />
                </div>
              </div>

              <div className={mobilenavItemExpand3 ? 'navAnim active navItemExpandMobile' : 'navAnim navItemExpandMobile'}>
                <div className='navItem'>
                  <Link to='/faqs'>FAQs</Link>
                </div>
                <div className='navItem'>
                  <Link to='/sizeguide'>Size Guide</Link>
                </div>
                <div className='navItem'>
                  <Link to='/labdiamondsguidance'>Lab Diamond Guidance</Link>
                </div>

              </div>

            </div>

            <div className='w-full flex justify-between items-end'>
              <div>
                <div className='navItem'>
                  <Link to=''>Privacy Policy</Link>
                </div>
                <div className='navItem'>
                  <Link to=''>Terms and Conditions</Link>
                </div>
              </div>

              <div className='flex gap-4'>
                <Link to='https://www.instagram.com/moheerajewels?igsh=MTNxMGF4NnQ4cnpvNg==' target='_blank'><img src={ig} className='h-6 max-h-[100%]' /></Link>
                <Link to='mailto:moheerajewels@gmail.com' target='_blank'><img src={mail} className='h-6 max-h-[90%]' /></Link>
              </div>
            </div>

          </div>
        </div>

        {isSearchOpen &&
          <div className='navMobileSearch'>
            <input
              className='input font-subheading' type='text' placeholder='search product code'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value.toUpperCase())}
            />
            <button className='w-4 max-w-[100%] cursor-pointer' onClick={findSearchedInput}> Search </button>
          </div>
        }


      </div>

      {cartIsOpen ? <Cart /> : <></>}

    </>
  )
}

export default Nav

//   < div className = { isNavOpen? 'NavMenu navAnim active': 'NavMenu navAnim' } >

// <div>
//   <div className='navHead'>SHOP</div>

//   <div className='navItem'>
//     <Link to='/collection'>SHOP BY CATEOGRY</Link>
//   </div>
//   <div className={navItemExpand ? 'navItem selected' : 'navItem'} onClick={() => setNavItemExpand(!navItemExpand)}>
//     SHOP BY COLLECTION
//   </div>

//   <div className={navItemExpand ? 'navAnim active navItemExpand' : 'navAnim navItemExpand'}>
//     <div className='navItem'>
//       <Link to='/collection'>on the move</Link>
//     </div>
//     <div className='navItem'>
//       <Link to='/collection'>essentials</Link>
//     </div>
//   </div>

//   <div className='navItem'>
//     <Link to='/collection'>NEW ARRIVALS</Link>
//   </div>
// </div>



// <div>
//   <div className='navHead'>DISCOVER</div>

//   <div className='navItem'>
//     <Link to='/ourstory'>about us</Link>
//   </div>
//   <div className='navItem'>
//     <Link to='/bespokeprocess'>Bespoke Process</Link>
//   </div>
//   <div className='navItem'>
//     <Link to='/'>Love letters</Link>
//   </div>
//   <div className='navItem'>
//     <Link to=''>Book an Appointment</Link>
//   </div>
//   <div className='navItem'>
//     <Link to=''>Contact</Link>
//   </div>
//   <div className='navItem'>
//     <Link to=''>Connect on Instagram</Link>
//   </div>

// </div>




// <div>
//   <div className='navHead'>Care</div>

//   <div className='navItem'>
//     <Link to=''>FAQs</Link>
//   </div>
//   <div className='navItem'>
//     <Link to=''>Size Guide</Link>
//   </div>
//   <div className='navItem'>
//     <Link to=''>Lab Diamond Guidance</Link>
//   </div>
//   <div className='navItem'>
//     <Link to=''>Privacy POlicy</Link>
//   </div>
//   <div className='navItem'>
//     <Link to=''>Terms and Conditions</Link>
//   </div>

// </div>

// </div >