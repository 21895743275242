import logo from './logo.svg';
import {useRef} from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Layout from './Layout';
import Home from './pages/Home';
import Collection from './components/Collection/Collection';
import { ShoppingCartProvider } from './context/ShoppingCartContext';
import OurStory from './components/OurStory/OurStory';
import Bespoke from './components/Bespoke/Bespoke';
import Cart from './components/Cart/Cart';
import AdminLogin from './AdminPages/AdminLogin';
import RequireAuth from './components/RequireAuth';
import AdmindashBoard from './AdminPages/AdmindashBoard';
import BookAppointment from './components/BookAppointment/BookAppointment';
import Contact from './components/Contact/Contact';
import Faq from './components/Faq/Faq';
import LabGuidance from './components/LabGuidance/LabGuidance';
import ProductPage from './components/ProductPage/ProductPage';
import CustomEngRings from './components/CustomEngRings/CustomEngRings';
import JewelleryCare from './components/JewelleryCare/JewelleryCare';
import SizeGuide from './components/SizeGuide/SizeGuide';
import ShopCollection from './components/ShopCollection/ShopCollection';
import OntheMove from './components/OntheMove/OntheMove';
import Checkout from './components/Checkout/Checkout';

function App() {
  
// deploy comment responsive  11 /11

const loveletters_ref = useRef(null)

  return (

    <ShoppingCartProvider>
      <Routes>

        <Route path="/" element={<Layout loveletters_ref={loveletters_ref}/>}>

          <Route index element={<Home loveletters_ref={loveletters_ref} />} />

          <Route path="ourstory" element={<OurStory />} />
          <Route path="collection" element={<Collection />} />
          <Route path="collection/:id" element={<Collection />} />
          <Route path="bespokeprocess" element={<Bespoke />} />
          <Route path="customengagementrings" element={<CustomEngRings />} />
          <Route path="bookanappointment" element={<BookAppointment />} />
          <Route path="contactus" element={<Contact />} />
          <Route path="faqs" element={<Faq />} />
          <Route path="labdiamondsguidance" element={<LabGuidance />} />
          <Route path="jewellerycare" element={<JewelleryCare />} />
          <Route path="sizeguide" element={<SizeGuide />} />
          <Route path="shopcollection" element={<ShopCollection />} />
          <Route path="onthemove" element={<OntheMove />} />

          <Route path="product/:id" element={<ProductPage/>}/>
          <Route path="checkout" element={<Checkout/>}/>

          {/* <Route path="cart" element={<Cart />} /> */}

          {/* <Route path="search/:value" element={<SearchPage searchValue={searchValue} setSearchValue={setSearchValue} />}/> */}

          <Route path="admin" element={<AdminLogin />} />

          <Route element={<RequireAuth />}>
            <Route path="admindashboard" element={<AdmindashBoard />} />

          </Route>

        </Route>

      </Routes>
    </ShoppingCartProvider>
  );
}

export default App;
