import React from 'react'
import './Footer.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from '../../assets/logodark.png'
import watermark from '../../assets/watermark.png'
import ig from '../../assets/icons/iglight.png'
import mail from '../../assets/icons/maillight.png'

const Footer = ({ loveletters_ref }) => {

    const location = useLocation()
    const navigate = useNavigate()

    const scrollToLoveletters = async () => {
        if (location.pathname == '/') {
            loveletters_ref.current?.scrollIntoView({ behavior: 'smooth' })
        }
        else {
            await navigate('/');
            loveletters_ref.current?.scrollIntoView({ behavior: 'smooth' })
        }
    }

    return (
        <div className='Footer'>

            <div className='flex flex-col justify-between items-center'>
                <div className='flex flex-col items-center gap-2'>
                    <div className='footerLogo'>
                        <img src={logo} />
                    </div>

                    <div className='font-bodyone text-[15px] text-center md:text-left max-w-[300px] md:max-w-[150px]'>
                        Stay in the loop and never miss an update from Moheera. Follow us on instagram.
                    </div>
                </div>

                <div className='footerWatermark'>
                    <img src={watermark} />
                </div>
            </div>

            <div>
                <div className='footerMenu'>

                    <div>
                        <div className='footerHead'>Company</div>

                        <div className='footerItem'>
                            <Link to='/bookanappointment'>Book an Appointment</Link>
                        </div>
                        <div className='footerItem'>
                            <Link to='/ourstory'>About us</Link>
                        </div>
                        <div className='footerItem'>
                            <Link to='/bespokeprocess'>Bespoke Process</Link>
                        </div>
                        <div className='footerItem'>
                            <Link to='/customengagementrings'>Custom Engagement Rings</Link>
                        </div>
                        <div className='footerItem cursor-pointer'
                        onClick={scrollToLoveletters}
                        >
                            Love letters
                        </div>

                    </div>

                    <div className='footerline'></div>

                    <div>
                        <div className='footerHead'>Legal</div>
                        <div className='footerItem'>
                            <Link to=''>Shipping Policy</Link>
                        </div>
                        <div className='footerItem'>
                            <Link to=''>Return Policy</Link>
                        </div>
                        <div className='footerItem'>
                            <Link to=''>Buy Back</Link>
                        </div>
                        <div className='footerItem'>
                            <Link to=''>Privacy</Link>
                        </div>
                        <div className='footerItem'>
                            <Link to=''>Terms & Conditions</Link>
                        </div>
                    </div>

                    <div className='footerline'></div>


                    <div>
                        <div className='footerHead'>Customer Care</div>
                        <div className='footerItem'>
                            <Link to='/faqs'>FAQs</Link>
                        </div>
                        <div className='footerItem'>
                            <Link to='/sizeguide'>Sizing Guide</Link>
                        </div>
                        <div className='footerItem'>
                            <Link to='/jewellerycare'>Jewellery Care</Link>
                        </div>
                        <div className='footerItem'>
                            <Link to='/labdiamondsguidance'>Lab Diamonds</Link>
                        </div>
                        <div className='footerItem'>
                            <Link to='/contactus'>Contact us</Link>
                        </div>
                    </div>

                </div>

                <div className='flex gap-6 justify-end font-subheading mt-4'>
                    {/* <div className='text-center'>
                        Follow us on <br /> <span className='font-body'>Instagram</span>
                    </div>
                    <div className='text-right'>
                        contact us on <br /> <span className='font-body'>moheerajewels@gmail.com</span>
                    </div> */}
                    <Link to='https://www.instagram.com/moheerajewels?igsh=MTNxMGF4NnQ4cnpvNg==' target='_blank'><img src={ig} className='h-6 max-h-[100%]' /></Link>
                    <Link to='mailto:moheerajewels@gmail.com' target='_blank'><img src={mail} className='h-6 max-h-[90%]' /></Link>
                </div>
            </div>

        </div>
    )
}

export default Footer