import React, { useEffect, useState } from 'react'
import './ProductPage.css'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import img from '../../assets/product1.png';
import subimg from '../../assets/prodpage.png';
import share from '../../assets/icons/share.png';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import ProductDisplay from '../Home/ProductDisplay/ProductDisplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';


const ProductPage = () => {

    const { id } = useParams();

    const [product, setProduct] = useState()
    const [metalSelected, setMetalSelected] = useState("")
    const [option, setOption] = useState()
    const [shape, setShape] = useState()
    const [price, setPrice] = useState("")


    useEffect(() => {
        window.scrollTo(0, 0)

        const getData = async () => {
            try {
                const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/getproductdata?id=${id}`);
                setProduct(data.data);
            }
            catch (err) { console.log(err); }
        }

        getData()
        console.log(product)
    }, [id])

    const { getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart } = useShoppingCart();

    const quantity = getItemQuantity(product?._id);

    const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);
    console.log(product)

    useEffect(() => {
        if (product) {
            // If both option and metalSelected are defined
            if (option && metalSelected) {
                const selectedPrice = product.customPrices[option]?.find(
                    (item) => item.metal === metalSelected
                );
                console.log(selectedPrice ? selectedPrice.price : 'Price not found');
                setPrice(selectedPrice?.price);
            }

            // If only metalSelected is defined but no option is selected
            else if (!option && metalSelected) {
                const selectedPrice = Object.values(product.customPrices).flat().find(
                    (item) => item.metal === metalSelected
                );
                console.log(selectedPrice ? selectedPrice.price : 'Price not found');
                setPrice(selectedPrice?.price);
            }

            else if (option && !metalSelected) {
                // Get the selected option (e.g., 'HalfBand', 'FullBand') and access its metals
                const selectedOption = product.customPrices[option];
                const firstMetal = selectedOption ? selectedOption[0] : null;  // Get the first metal from the selected option
                console.log(firstMetal ? firstMetal.price : 'Price not found');
                setPrice(firstMetal?.price || ''); // Set price to the first metal's price or an empty string
            }

            // If neither option nor metalSelected is defined
            else if (!option && !metalSelected) {
                const firstOption = Object.values(product.customPrices)[0]; // Get the first option (e.g., HalfBand)
                const firstMetal = firstOption ? firstOption[0] : null;  // Get the first metal from the first option
                console.log(firstMetal ? firstMetal.price : 'Price not found');
                setPrice(firstMetal?.price || ''); // Set price to the first metal's price or an empty string
            }
        }
    }, [metalSelected, option, id, product]);

    const handleShare = async () => {
        const currentLink = window.location.href;
        if (navigator.share) {
          // Use the native share option if available
          try {
            await navigator.share({
              title: "Check this out!",
              url: currentLink,
            });
          } catch (error) {
            console.error("Error sharing:", error);
          }
        } else {
          // Fallback: Copy link to clipboard
          try {
            await navigator.clipboard.writeText(currentLink);

          
            alert("Link copied to clipboard!");
          } catch (error) {
            console.error("Failed to copy link:", error);
          }
        }
      };

    return (
        <>
            <div className='ProductPage'>

                <div className='hidden md:flex gap-8 text-sm px-[72px]'>
                    <button className='C_shopBtns selected'>shop all products</button>
                    <button className='C_shopBtns'>shop rings</button>
                    <button className='C_shopBtns'>shop earrings</button>
                    <button className='C_shopBtns'>shop wristwear</button>
                    <button className='C_shopBtns'>shop necklaces</button>
                </div>

                <div className='text-[10px] text-white text-left px-6 md:px-[88px] uppercase'>
                    <div>
                        <Link to='/' className='pinkHover'>HOME</Link> / <Link to='/collection' className='pinkHover'>SHOP {product?.category}</Link> / {product?.name}
                    </div>
                </div>

                {product && <>
                    <div className='productPageGrid'>
                        <div className='PP_imgDiv'>
                            {product?.imageURLs.length > 0 &&
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={0}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                >
                                    {product?.imageURLs.map((item, i) =>

                                        <SwiperSlide key={i} className='my-auto h-fit'>
                                            <img src={item.url} />
                                        </SwiperSlide>
                                    )}

                                </Swiper>
                            }
                        </div>

                        <div className='PP_mainInfo'>
                            <div className='flex items-start justify-between gap-2 uppercase'>
                                <div>
                                    <div className='text-[32px] sm:text-[48px] font-heading leading-[28px] sm:leading-[40px] text-[var(--primary)]'>{product?.name}</div>
                                    <div className='mb-4'>{product?.code}</div>
                                    <div className='text-[var(--primary)] text-[18px] sm:text-[22px]'>price in inr {price}</div>
                                </div>
                                <div
                                  className="p-2 cursor-pointer  transition duration-200 ease-in-out"
                                  onClick={handleShare}
                                ><img src={share} /></div>
                            </div>

                            {Object.keys(product?.customPrices).length > 1 &&
                                <div className='PP_selectFlex'>
                                    <div className='PP_selectLabel'>
                                        {product.category == 'Rings' && 'BAND TYPE'}
                                        {product.category == 'Earrings' && 'WITH/ WITHOUT HALO'}
                                    </div>
                                    <select className='input font-subheading PP_select'
                                        onChange={(e) => setOption(e.target.value)}
                                        value={option}
                                    >
                                        {Object.keys(product?.customPrices).map(e =>
                                            <option value={e}>
                                                {e}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            }

                            <div className='PP_selectFlex'>
                                <div className='PP_selectLabel'>METAL SPECIFICATION</div>
                                <select className='input font-subheading PP_select'
                                    onChange={(e) => setMetalSelected(e.target.value)}
                                    value={metalSelected}
                                >
                                    {Object.values(product?.customPrices)[0].map(e =>
                                        <option value={e.metal}>
                                            {e.metal}
                                        </option>
                                    )}
                                </select>
                            </div>

                            {product?.options.length > 0 &&
                                <div className='PP_selectFlex'>
                                    <div className='PP_selectLabel'>SHAPE</div>
                                    <select className='input font-subheading PP_select'
                                        onChange={(e) => setShape(e.target.value)}
                                        value={shape}
                                    >
                                        {product?.options.map(e =>
                                            <option value={e}>
                                                {e}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            }

                            {/* <div className='PP_selectFlex'>
                                <div className='PP_selectLabel'>FINISH</div>
                                <select className='input font-subheading PP_select'>
                                    <option value=''>
                                        Rose Gold
                                    </option>
                                </select>
                            </div> */}

                            {product.category == 'Rings' &&
                                <>
                                    <div className='PP_selectFlex'>
                                        <div className='PP_selectLabel'>SIZE</div>
                                        <select className='input font-subheading PP_select'>
                                            {/* {product?.customPrices?.map(e => */}
                                            <option value=''>
                                                55 mm
                                            </option>
                                            {/* )} */}
                                        </select>
                                    </div>

                                    <Link to='/sizeguide'><div className='text-[10px]'>SIZE GUIDE</div></Link>
                                </>
                            }

                            {quantity === 0 ? (

                                <button
                                    onClick={() => { increaseCartQuantity(product?._id); setShowAddedToCartModal(true) }}
                                    className='PP_actionBtn'
                                >
                                    + Add to Cart
                                </button>
                            ) :
                                <div className='flex gap-8 items-center PP_mobileWidthDec'>

                                    <div className='flex gap-0 justify-stretch'>

                                        <button
                                            onClick={() => decreaseCartQuantity(product?._id)}
                                            className='bg-white text-[var(--secondary)] border border-white flex items-center text-lg rounded-none h-[36px]'
                                        >
                                            -
                                        </button>

                                        <div className='border border-white text-white px-4 rounded-none h-[36px] flex items-center'>
                                            {quantity}
                                        </div>


                                        <button
                                            onClick={() => increaseCartQuantity(product?._id)}
                                            className='bg-white text-[var(--secondary)] border border-white flex items-center text-lg rounded-none h-[36px]'
                                        >
                                            +
                                        </button>

                                    </div>

                                    {/* <div className=''> */}
                                    <button onClick={() => removeFromCart(product?._id)}
                                        className='PP_actionBtn'>
                                        Delete
                                    </button>
                                    {/* </div> */}
                                </div>
                            }

                            <div className='font-bodyone PP_mobileWidthDec'>
                                {product?.about}
                            </div>
                        </div>

                    </div>

                    <div className='productPageGrid'>
                        <div className='PP_subInfo'>
                            <div className='text-[var(--primary)]'>PRODUCT INFORMATION</div>
                            <div className='font-bodyone mt-2'>
                                Product Code: {product?.code} <br />
                                Estimated Gold Weight: {product?.goldWeight}g <br />
                                Estimated Diamond Weight: {product?.diamondWeight}cttw <br />
                            </div>

                            <div className='text-[var(--primary)] mt-6'>DIAMOND & METAL INFORMATION</div>
                            <div className='font-bodyone mt-2'>
                                Diamonds<br />
                                1. Diamonds under 1ct: All are EF color with VVS-VS clarity, IGI/SGL certified and hallmarked after production.<br />
                                2. Diamonds over 1ct: Pre-certified and set according to your design preferences.<br />

                                Metals<br />
                                1. 14kt Gold & Silver: Affordable, durable, and great for everyday wear. Silver may need occasional polishing.<br />
                                2. 18kt & 22kt Gold: Brighter yellow finish, slightly more expensive and prone to bending.

                            </div>

                            <div className='font-bodyone mt-6'>
                                For more details or customization options, please schedule a <br /><Link to='/bookanappointment' className='pinkHoverA'>discovery call</Link> or <Link to='/contactus' className='pinkHoverA'>contact us</Link> via WhatsApp. We’re here to help!
                            </div>

                        </div>
                        <div className='PP_subImg'>
                            <Link to='/collection'><img src={subimg} /></Link>
                        </div>
                    </div>
                </>}

                {!product &&
                    <div>Search Not Found <br /><Link to='/collection' className='font-subheading hover:text-white'>shop here</Link></div>
                }

                <div className='mt-20'>
                    <div className='text-white text-left px-5 sm:px-[72px]'>OTHER PRODUCTS THAT MAY INTEREST YOU</div>
                    <ProductDisplay />
                </div>

                <div className='max-w-[1100px] mx-5 lg:mx-auto w-[calc(100%-40px)] lg:w-full mt-10'>
                    <div className='font-heading text-white text-[48px] text-left'>See More</div>
                    <div className='flex gap-4'>
                        <div className='bg-white w-full h-[150px] sm:h-[250px] relative'>
                            <div className='absolute bottom-4 left-0 right-0 seemoreLink'>EXPLORE NEW pendants</div>
                        </div>
                        <div className='bg-white w-full h-[150px] sm:h-[250px] relative'>
                            <div className='absolute bottom-4 left-0 right-0 seemoreLink'>EXPLORE earrings</div>
                        </div>
                    </div>
                </div>

            </div>

            {showAddedToCartModal ?
                <div className='AddedToCartModalContainer'>
                    <div className='AddedToCartModal'>
                        <div className='absolute top-[-12px] right-[6px] text-4xl flex items-center cursor-pointer'
                            onClick={() => setShowAddedToCartModal(false)}
                        >
                            ×
                        </div>

                        <div className='flex justify-center items-center sm:items-end gap-8 flex-col sm:flex-row'>
                            <div>
                                <div className='font-heading text-3xl uppercase'>{product.name}</div>
                                <div className='font-heading uppercase'>{product.code}</div>
                                <div className='font-subheading'>For INR {price}</div>
                            </div>

                            <div className='modal_imgDiv'>
                                <img src={product?.imageURLs[0]?.url} />
                            </div>
                        </div>

                        <div className='text-[var(--secondary)] mt-8'>ADDED TO CART!</div>
                    </div>
                </div>
                : null}

        </>
    )
}

export default ProductPage